import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { MDXRenderer } from "gatsby-plugin-mdx";
import './LegalPageContent.scss';


const LegalPageContent = ({ data }) => (
  <div className="aaa-legal-page">
    <ContentBox>
      <div className="aaa-legal-page__content">
          <MDXRenderer>{data.body}</MDXRenderer>
      </div>
    </ContentBox>
  </div>
);

export default LegalPageContent;